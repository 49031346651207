.top-banner {
    position: relative;
    width: 100%;
    background-size: cover;
    background-image: url("../../images/top-image.jpg");
    color: #fff;
    text-align: center;
    padding: 150px 20px;
    background-repeat: no-repeat;
    background-position: center center;
}

/* .banner-inner-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
} */

.top-banner h1 {
    font-size: 40px;
    margin-bottom: 0px;
}

.top-banner a,
.top-banner span {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
}
@media screen and (max-width:992px){
    .top-banner {
        padding: 100px 20px;
    }
}