.header {
  padding: 15px 0px;
}
.header .logo {
  margin-top: 10px;
}
.header .logo>a {
  display: inline-block;
}
.header .logo img {
  max-width: 80px;
  width: 100%;
}
.header .header-right-sec {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}
.header .header-right-sec a {
  color: #000;
  font-size: 20px;
  text-decoration: none;
  display: block;
}
.header-right-sec svg {
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .header .logo {
    margin-top: 0px;
  }
  .header {
    padding: 10px 0px;
  }
  .header .header-right-sec a {
    font-size: 0px;
  }
  .header .header-right-sec a.email {
    display: none;
  }
  .header-right-sec svg {
    margin-right: 5px;
    font-size: 25px;
  }
  .header .col {
    width: 85%;
    flex: 85%;
  }
  .header .col:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 15%;
    flex: 15%;
  }
}