.main-banner {
  text-align: center;
}
.home-inner-sec .tech-img {
  text-align: center;
}
.services-sec {
  background-color: #ffffff;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  margin-bottom: 20px;
  transition-duration: 0.3s;
}
.services-sec:nth-child(even){
  margin-left: 50px;
}
.services-sec:last-child{
  margin-bottom: 0px;
}
.service-content > h4 {
  margin-bottom: 0px;
}
.service-icon,
.service-content {
  width: 15%;
  display: inline-block;
  vertical-align: middle;
}
.service-content {
  width: 85%;
}
.donate-wrap-sec{
  background-color: #808080;
  padding: 40px 0px;
}
.donate-wrap-sec .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.donate-wrap-sec .col:last-child{
  display: flex;
  align-items: center;
}
.donate-wrap-sec h2,
.donate-wrap-sec h3{
  margin-bottom: 0px;
  font-size: 20px;
  color: #fff;
}
.donate-wrap-sec h3{
  font-size: 50px;
  line-height: 54px;
  margin-top: 15px;
}
.tech-about {
  margin-bottom: 30px;
}
.donate-wrap-sec .col-lg-6 {
  width: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.donate-wrap-sec .col-lg-6:last-child{
  align-items: flex-end;
}
@media screen and (max-width:1130px) {
.services-sec:nth-child(2n) {
  margin-left: 0px;
}
}
@media screen and (max-width:992px) {
  .donate-wrap-sec {
    padding: 20px 0px;
  }
  .donate-wrap-sec h3 br{
    display: none;
  }
.donate-wrap-sec h3 {
  font-size: 30px;
  line-height: 34px;
}
.services-sec{
  max-width: 100%;
  width: 100%;
}
.donate-wrap-sec .col-lg-6 {
  width: 100%;
}
}
@media screen and (max-width:768px) {
  .home-inner-sec hr:last-child {
    display: none;
  }
  .service-icon{
    width: 25%;
}
.service-content {
  width: 75%;
}
.tech-about h2,
.tech-about h3{
  font-size: 20px;
}
}