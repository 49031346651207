.navbar-light .navbar-brand {
  color: #fff;
}
nav.navbar {
  padding: 5px 0px;
}
.navbar-toggler{
  padding: 0px;
}
.navbar-nav .nav-link{
 font-size: 18px;
 color: #fff !important;
 padding-right: 20px !important;
 padding-left: 20px !important;
 padding-top: 15px;
 padding-bottom: 15px;
}
.navbar-nav .nav-link.active{
font-weight: 700;
}
.nav-item.show.dropdown > a::after {
  transform: rotate(180deg);
}
.dropdown-menu{
  min-width: 180px !important;
  margin-top: 0px;
  border-radius: 0px;
  border: 0px;
}
.dropdown-item{
  padding: 8px 15px !important;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #fff;
}
.navbar-light .navbar-toggler {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.navbar-collapse {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100%;
  padding-bottom: 20px;
  z-index: 9999;
  border-bottom:10px solid #000 ;
}
.dropdown-menu.show {
  margin: 0px 20px;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
}
.navbar .container{
  padding: 0px 20px;
}
.dropdown-toggle::after {
  margin-left: 10px;
}
.navbar-nav .nav-link:focus-visible{
  outline: none !important;
}
@media screen and (max-width: 992px) {
  .dropdown-menu {
    min-width: 150px !important;
  }
  .dropdown-item {
    font-size: 15px;
  }
.navbar-nav .nav-link {
  font-size: 16px;
  color: #fff !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}
}
@media screen and (min-width: 768px) {
  .navbar-nav {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
  }
.navbar-brand{
  display: none;
}
.navbar-collapse {
  position: static;
  padding-bottom: 0px;
  border-bottom: 0px !important;
}
nav.navbar {
  padding: 0px;
}
}