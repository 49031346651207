*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  position: relative;
  min-height: 100%;
}
body {
  margin: 0;
  font-family: "roboto";
  color: #000;
  font-size: 16px;
  background-color: #f7fafe;
  margin-bottom: 60px;
}
a{
  color: #000;
}
h1,h2,h3,h4,h5,h6{
  margin-top: 0px;
}
h1{
  font-size: 35px;
  margin-bottom: 30px;
}
h2{
  font-size: 30px;
  margin-bottom: 25px;
}
h3{
  font-size: 25px;
  margin-bottom: 20px;
}
h4{
  font-size: 20px;
  margin-bottom: 15px;
}
h5{
  font-size: 18px;
  margin-bottom: 13px;
}
h6{
  font-size: 16px;
  margin-bottom: 10px;
}
ul{
  margin: 0px;
  padding: 0px 0px 0px 30px !important;
  list-style-type: none;
}
ul li{
  position: relative;
}
li:before {
  border-right: 2px solid #6ec1e4;
  border-bottom: 2px solid #6ec1e4;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  position: absolute;
  content: "";
  left: -20px;
  top: 6px;
}
p{
  font-size: 18px;
  line-height: 30px;
  color: #000;
  margin-bottom: 15px;
}
p:last-child{
  margin-bottom: 0px;
}
p > strong{
  display: block;
  margin-bottom: 5px;
}
label {
  display: inline-block;
  margin-bottom: 10px;
}
.generic-btn{
  background-color: #55c882;
  color: #fff;
  font-size: 16px;
  max-width: 200px;
  width: 100%;
  display: block;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  border: none;
}
.generic-btn:hover{
  color: #fff;
}
.right{
  float: right;
}
.left{
  float: left;
}
.text-center{
  text-align: center;
}
.text-area-field{
  height: 100px !important;
}
.orange-color{
  color: #ff8b3c !important;
}
@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-black-webfont.woff2') format('woff2'),
       url('./fonts/roboto-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-blackitalic-webfont.woff2') format('woff2'),
       url('./fonts/roboto-blackitalic-webfont.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-bold-webfont.woff2') format('woff2'),
       url('./fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-bolditalic-webfont.woff2') format('woff2'),
       url('./fonts/roboto-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-italic-webfont.woff2') format('woff2'),
       url('./fonts/roboto-italic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-light-webfont.woff2') format('woff2'),
       url('./fonts/roboto-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-lightitalic-webfont.woff2') format('woff2'),
       url('./fonts/roboto-lightitalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-medium-webfont.woff2') format('woff2'),
       url('./fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-mediumitalic-webfont.woff2') format('woff2'),
       url('./fonts/roboto-mediumitalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-regular-webfont.woff2') format('woff2'),
       url('./fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-thin-webfont.woff2') format('woff2'),
       url('./fonts/roboto-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'roboto';
  src: url('./fonts/roboto-thinitalic-webfont.woff2') format('woff2'),
       url('./fonts/roboto-thinitalic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
img{
  width: auto;
  max-width: 100%;
}
.container{
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 30px;
}
.nav-link{
  padding: 0px;
}
.green-bg,
.counter-sec-wrap,
nav.navbar,
.dropdown-item:focus, .dropdown-item:hover,
.navbar-collapse{
  background-color: #55c882 !important;
}
.text-green,
.contact-sec-wrap .company-info svg,
.services-box-wrap .service-box-inner svg{
  color: #55c882;
}
.main-sec{
  padding: 50px 0px;
}
.box-shadow{
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
}
.margin-bottom-80{
  margin-bottom: 80px !important;
}
.generic-field{
  width: 100%;
  height: 49px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
}
.generic-field:focus-visible{
 outline: none;
 border: 1px solid #ccc;
}
.services-box-wrap {
  text-align: center;
  margin-top: 0px;
}
.services-box-wrap .col {
  flex: 50%;
  width: 50%;
  max-width: 50%;
  margin: 0px auto 20px;
}
.services-box-wrap .service-box-inner{
  text-align: center;
  border: 1px solid #000;
  padding: 40px 20px;
  height: 100%;
}
.services-box-wrap .service-box-inner h2{
margin-bottom: 10px;
font-size: 20px;
}
.services-box-wrap .service-box-inner p{
  font-size: 16px;
}
.services-box-wrap .service-box-inner svg{
  font-size: 35px;
  margin-bottom: 15px;
}
.counter-sec-wrap{
  padding: 40px;
  }
  .counter-sec{
    background-color: #ffffff;
    padding: 30px 20px;
    height: 100%;
  }
  .counter-sec h4{
    font-size: 35px;
    margin-bottom: 10px;
  }
  .counter-sec img {
    width: 60px;
  }
  .overview-table{
    margin-top: 50px;
    overflow-x: auto;
  }
  .project-top-wrap{
    padding-right: 20px;
    margin-bottom: 40px;
  }
  .tech-about .desc{
    margin-bottom: 30px;
  }
  .donation-channels {
    padding: 70px 0px;
  }
  .pictures-sec .col{
    width: 33.33%;
    flex: 33.33%;
    max-width: 33.33%;
    margin-bottom: 30px;
  }
  .pictures-sec h2{
    margin-bottom: 5px;
  }
  .pictures-sec img{
    margin-bottom: 20px;
    width: 200px;
  }
  .pictures-sec .generic-btn{
    margin: 0 auto;
  }
  .monthly-pictures-sec .container{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  } 
  .monthly-pictures-sec .col{
    width: 25%;
    flex: 25%;
    max-width: 25%;
    margin-bottom: 20px;
  }
  .plantaion-gallery .col{
    width: 20%;
    flex: 20%;
    max-width: 20%;
    margin-bottom: 20px;
  }
  .plantaion-gallery img{
    margin-bottom: 10px;
  }
  .plantaion-gallery a,
  a {
    text-decoration: none;
  }
  .overview-detail .col{
    width: 33.33%;
    flex: 33.33%;
    max-width: 33.33%;
    margin-bottom: 20px;
  }
  @media screen and (max-width:992px) {
  .counter-sec-wrap {
    padding: 20px;
  }
  .margin-bottom-80 {
    margin-bottom: 40px !important;
  }
  .counter-sec {
    padding: 15px;
  }
  .sm-text-center{
    text-align: center;
  }
  .donate-wrap-sec .generic-btn.right {
    float: none;
    margin: 20px auto 0px;
  }
}
@media screen and (max-width:768px) {
  .main-sec{
    padding: 30px 0px;
  }
  h1{
    font-size: 25px;
  }
  h1,h2,h3,h4,h5,h6{
    margin-bottom: 10px;
  }
  p {
    font-size: 15px;
    line-height: 27px;
  }
  .counter-sec-wrap {
    padding: 10px 5px;
  }
  .counter-sec-wrap .col{
    margin-bottom: 20px;
    flex: 50%;
    margin-bottom: 10px;
    width: 50%;
    padding: 0px 5px;
  }
  .counter-sec-wrap .col:nth-child(3),
  .counter-sec-wrap .col:last-child{
    margin-bottom: 0px;
  }
  .counter-sec h4 {
    font-size: 30px;
  }
  .services-box-wrap {
    padding: 0px 5px;
  }
  .services-box-wrap .service-box-inner {
    padding: 20px;
  }
  .services-box-wrap .service-box-inner h2 {
    font-size: 16px;
  }
  .services-box-wrap .service-box-inner p {
    font-size: 14px;
    line-height: 24px;
  }
  .services-box-wrap .col {
    padding: 0px 5px;
    margin-bottom: 10px;
  }
  .footer p{
    font-size: 18px !important;
  }
  .overview-table{
    margin-top: 00px;
  }
  .top-banner h1 {
    font-size: 30px !important;
    margin-bottom: 10px !important;
  }
  .project-top-wrap {
    margin-bottom: 20px;
  }
  .extra-space{
    padding: 100px 0px;
  }
  .pictures-sec .col,
  .monthly-pictures-sec .col,
  .overview-detail .col{
    width: 100%;
    flex: 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }
  .plantaion-gallery .col{
    width: 50%;
    flex: 50%;
    max-width: 50%;
    margin-bottom: 20px;
  }
}