.banner-effect{
    position: relative;
    height: 385px;
    width: 100%;
    background-size: cover;
}
.banner-effect:before{
    background-color: #000000;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
}
.carousel-caption{
    position: absolute;
    max-width: 800px;
    width: 100%;
    left: 3%;
    top: 10%;
    text-align: left;
}
.carousel-caption h2{
    font-size: 16px;
    color: #fff;
    border-bottom: 2px solid #55c882;
    display: inline-block;
    padding-bottom: 10px;
}
.carousel-caption h3{
    font-size: 60px;
    color: #fff;
}
.carousel-caption p{
    font-size: 16px;
    color: #fff;
}
@media screen and (max-width: 992px){
    .carousel-control-prev,
    .carousel-control-next{
display: none;
    }
    .banner-effect {
        height: 280px;
      }
    .carousel-caption{
        left: 50%;
        right: 0;
        top: 50%;
        bottom: 0;
        max-width: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .carousel-caption h2{
        margin-bottom: 0px;
    } 
    .carousel-caption h3{
        font-size: 30px;
    }
    .carousel-caption p{
        display: none;
    } 
}