.contact-sec-wrap .row>h1,
.contact-sec-wrap .row>p {
  text-align: center;
}

.contact-sec-wrap .row>p {
  margin-bottom: 40px;
}

.contact-sec-wrap .company-info {
  text-align: center;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 40px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-sec-wrap .company-info svg {
  font-size: 30px;
  margin-bottom: 20px;
}

.contact-sec-wrap .company-info .nav-link {
  color: black;
  font-size: 20px;
}

.contact-sec-wrap .company-info address {
  margin-bottom: 0px;
  font-size: 20px;
}

.contact-sec-wrap .company-info span {
  display: block;
  font-size: 20px;
}

.contact-sec-wrap .col {
  width: 25%;
  flex: 25%;
  margin-bottom: 20px;
}

.form-sec-wrap ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  margin: 0px -20px;
}

.form-sec-wrap ul li {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  padding: 0px 20px;
  margin-bottom: 35px;
}

.generic-input-btn {
  margin: 40px auto 0px;
}

.form-sec-wrap h1 {
  text-align: center;
}
.form-group{
  position: relative;
}
.form-group .error-msg{
  font-size: 14px;
  color: #ad343e;
  position: absolute;
  left: 0;
  top: 100%;
}
.form-sec-wrap ul li.last{
 width: 100%;
}
.form-sec-wrap {
  max-width: 1000px;
  margin: 0 auto;
}
.error-field label{
  color: #ad343e;
}
.error-field .generic-field{
  border: 1px solid #ad343e;
}
@media screen and (max-width:1200px) {
  .contact-sec-wrap .col {
    width: 50%;
    flex: 50%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width:767px) {
  .contact-sec-wrap .company-info {
    padding: 20px 15px;
  }

  .map-sec-wrap {
    padding-top: 20px;
  }

  .map-sec-wrap .col:last-child {
    margin-top: 20px;
  }

  .contact-sec-wrap .company-info h3 {
    margin-bottom: 10px;
  }

  .contact-sec-wrap .col,
  .map-sec-wrap .col,
  .form-sec-wrap ul li {
    width: 100%;
    flex: 100%;
  }
}